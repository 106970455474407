import React, { useEffect, useState, useMemo } from 'react'
import fbApp from './fb'
import Card from './Card'
import BidSelect from './BidSelect'
import { useTransition, animated } from 'react-spring'

const playersRef = fbApp.database().ref('players')
const gameRef = fbApp.database().ref('game')

export const PlayerView = () => {
	const [player, setPlayer] = useState(null)
	const [nameField, setNameField] = useState('')
	const [game, setGame] = useState({})

	useEffect(() => {
		gameRef.on('value', (snapshot) => {
			setGame(snapshot.val())
		})
	}, [])

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const playerId = params.get('playerId')
		if (playerId) {
			playersRef.child(playerId).on('value', (snapshot) => {
				if (!snapshot.val()) {
					window.location = '/'
					return
				}
				setPlayer({ key: snapshot.key, ...snapshot.val() })
			})
		}
	}, [])

	const joinGame = () => {
		const newPlayer = playersRef.push({
			name: nameField,
			cards: [],
		})

		setPlayer({ key: newPlayer.key })

		playersRef
			.child(newPlayer.key)
			.once('value')
			.then((snapshot) => {
				setPlayer({ key: snapshot.key, ...snapshot.val() })
			})

		playersRef.child(newPlayer.key).on('value', (snapshot) => {
			setPlayer({ key: snapshot.key, ...snapshot.val() })
		})

		window.history.pushState(null, null, window.location + '?playerId=' + newPlayer.key)

		window.addEventListener('unload', () => {
			console.log('UNLOAD')
			//   playersRef.child(newPlayer.key).remove()
		})
	}

	const submitBid = (bid) => {
		const currentRound = game.currentRound || { bids: {} }

		gameRef.update({
			currentRound: {
				...currentRound,
				bids: { ...(currentRound.bids || {}), [player.key]: bid },
			},
		})
	}

	const playCard = (card) => {
		const filteredCards = player.cards.filter((c) => c.suit + c.face !== card.suit + card.face)
		console.log(filteredCards)
		playersRef.child(player.key).update({
			playedCard: card,
			cards: filteredCards,
		})
	}
	const bids = game?.currentRound?.bids || {}
	const bidSubmitted = bids[player?.key] >= 0

	const suitSortOrder = { S: 0, D: 1, C: 2, H: 3 }

	const sortedCards = useMemo(
		() =>
			player && player.cards
				? player.cards
						.sort((a, b) => {
							if (a.face > b.face) {
								return -1
							}
							return 1
						})
						.sort((a, b) => {
							if (suitSortOrder[a.suit] > suitSortOrder[b.suit]) {
								return -1
							}
							return 1
						}) || []
				: [],
		[player]
	)

	const transitions = useTransition(sortedCards, (item) => item.suit + item.face, {
		enter: { height: 85 },
		leave: { height: 0 },
	})

	const isCurrentPlayer = game && player && game.currentPlayer === player.key

	const canPlay = isCurrentPlayer && bidSubmitted

	return (
		<div
			style={{
				backgroundColor: canPlay ? '#2F4F2F' : '#393433',
				height: '100vh',
			}}
		>
			{!player && (
				<div style={{ padding: 12 }}>
					<h1 style={{ marginTop: 0, textAlign: 'center', color: '#E55934' }}>Go to hell!</h1>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<label style={{ margin: 8, fontSize: 24 }}>Enter your name</label>
						<input
							style={{
								margin: 8,
								padding: 12,
								backgroundColor: 'rgba(0,0,0,0.1)',
								border: '1px solid #E55934',
								color: '#E55934',
								fontSize: 24,
								textAlign: 'center',
							}}
							value={nameField}
							onChange={(e) => setNameField(e.target.value)}
						/>
						<button
							onClick={() => joinGame()}
							style={{
								backgroundColor: '#E55934',
								padding: 8,
								border: 0,
								margin: 8,
								fontSize: 24,
								color: 'white',
							}}
						>
							Join game
						</button>
					</div>
				</div>
			)}
			{player && (
				<div className="player-view">
					{player.cards && player.cards.length && (
						<BidSelect count={12} onSelect={submitBid} selected={bidSubmitted} />
					)}
					<div className={`card-stack ${canPlay && 'active'}`}>
						{player.cards && player.cards.length && canPlay && (
							<h2 style={{ marginTop: 0, textAlign: 'center' }}>It's your turn</h2>
						)}
						{transitions.length >= 1 &&
							transitions.map(({ item, props, key, state }) => {
								if (state === 'leave' && key === transitions.length) {
									props.height.setValue(0)
								}
								return (
									<animated.div
										key={key}
										style={props}
										className="card"
										onClick={() => {
											bidSubmitted >= 0 && canPlay && playCard(item)
										}}
									>
										<Card card={item} />
									</animated.div>
								)
							})}
					</div>
				</div>
			)}
		</div>
	)
}
