import React from 'react'

const BidSelect = ({ count, onSelect, selected }) => {
	return (
		<div className={`bid-select ${selected && 'bid-selected'}`}>
			<h2 style={{ fontSize: 18 }}>Submit your bid:</h2>
			<button
				className={`bid-button ${selected === 0 && 'selected'}`}
				key={'zero'}
				onClick={() => !selected && selected !== 0 && onSelect(0)}
				disabled={selected && selected !== 0}
			>
				{0}
			</button>
			{Array(count)
				.fill('')
				.map((_, i) => (
					<button
						className={`bid-button ${selected === i + 1 && 'selected'}`}
						key={i}
						onClick={() => !selected && selected !== 0 && onSelect(i + 1)}
						disabled={(selected || selected === 0) && selected !== i + 1}
					>
						{i + 1}
					</button>
				))}
		</div>
	)
}

export default BidSelect
