import React, { useEffect, useState, useMemo, useCallback } from 'react'
import fbApp from './fb'
import { omit, shuffle, zip } from 'lodash'
import Card, { Trump } from './Card'
import { PlayerContainer } from './PlayerContainer'
import { determineWinner } from './winnerCalculator'
import ScoreBoard from './ScoreBoard'

const playersRef = fbApp.database().ref('players')
const deckRef = fbApp.database().ref('deck')
const gameRef = fbApp.database().ref('game')

const SHOW_WINNER_TIMEOUT = 4000

const generateNewDeck = () => {
	const deck = []
	const suits = ['H', 'S', 'C', 'D']
	const faces = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
	for (let i = 0; i < suits.length; i++) {
		for (let j = 0; j < faces.length; j++) {
			deck.push({
				suit: suits[i],
				face: faces[j],
			})
		}
	}
	return deck
}

export const BoardView = () => {
	const [players, setPlayers] = useState({})
	const [deck, setDeck] = useState([])
	const [game, setGame] = useState()
	const [winnerDisplay, setWinnerDisplay] = useState(null)

	const sortedPlayers = useMemo(() => {
		if (players && game && game.currentRound) {
			const lastTrickId = [...(game.currentRound.tricks || [])].pop()

			const flatPlayers = Object.entries(players).map(([key, value]) => ({
				...value,
				key,
			}))

			const sorted = zip(flatPlayers.slice(0, 4), flatPlayers.slice(4, 8))
				.flat()
				.filter((e) => !!e)

			if (lastTrickId) {
				const lastWinnerIndex = flatPlayers.findIndex((p) => p.key === lastTrickId)

				const sortByWinner = []

				for (let i = 0; i < sorted.length; i++) {
					const targetIndex = lastWinnerIndex + i
					if (targetIndex >= sorted.length) {
						sortByWinner.push(sorted[targetIndex - sorted.length])
					} else {
						sortByWinner.push(sorted[lastWinnerIndex + i])
					}
				}
				return sortByWinner
			} else if (game.rounds) {
				const sortByRound = []

				for (let i = 0; i < sorted.length; i++) {
					const targetIndex = (game.rounds.length % sorted.length) + i
					if (targetIndex >= sorted.length) {
						sortByRound.push(sorted[targetIndex - sorted.length])
					} else {
						sortByRound.push(sorted[targetIndex])
					}
				}
				console.log(sortByRound)
				return sortByRound
			}

			return sorted
		}
	}, [players, game])

	const cardsPlayed = useMemo(() => {
		if (sortedPlayers) {
			return sortedPlayers.filter((p) => p.playedCard).length
		}
	}, [sortedPlayers])

	const scoreBoard = useMemo(() => {
		if (game && game.rounds && sortedPlayers) {
			const scores = []
			game.rounds.forEach((round, i) => {
				scores[i] = []
				sortedPlayers.forEach((player) => {
					let playerRoundScore = round.tricks
						? round.tricks.filter((t) => t === player.key).length
						: 0

					const playerBids = round.bids && round.bids[player.key]

					if (playerRoundScore === playerBids) {
						playerRoundScore += 10
					}
					scores[i].push({ player: player.key, tricks: playerRoundScore })
				})
			})
			console.log('SCIRES', scores)
			return scores
		}
	}, [game, sortedPlayers])

	// Players
	useEffect(() => {
		playersRef.once('value').then((snapshot) => {
			setPlayers(snapshot.val())
		})

		playersRef.on('child_added', (snapshot) => {
			setPlayers((s) => ({ ...s, [snapshot.key]: snapshot.val() }))
		})

		playersRef.on('child_changed', (snapshot) => {
			setPlayers((s) => ({ ...s, [snapshot.key]: snapshot.val() }))
		})

		playersRef.on('child_removed', (snapshot) => {
			setPlayers((s) => omit(s, snapshot.key))
		})
	}, [])

	// Set next player on turn
	useEffect(() => {
		if (
			game &&
			game.currentRound &&
			game.currentRound.bids &&
			sortedPlayers &&
			sortedPlayers.length > 1 &&
			Object.keys(game.currentRound.bids).length === sortedPlayers.length
		) {
			let currentPlayer
			if (sortedPlayers.some((player) => player.playedCard)) {
				currentPlayer = sortedPlayers[cardsPlayed]
			} else {
				currentPlayer = sortedPlayers[0]
			}

			if (currentPlayer && currentPlayer !== game.currentPlayer) {
				gameRef.update({ currentPlayer: currentPlayer.key })
			}
		}
	}, [sortedPlayers, game, cardsPlayed])

	// End trick when all players have played a card
	useEffect(() => {
		if (
			sortedPlayers &&
			sortedPlayers.length === cardsPlayed &&
			game &&
			deck.length &&
			sortedPlayers.every((player) => player.playedCard)
		) {
			const trickWinner = determineWinner(sortedPlayers, deck[0])
			if (trickWinner && !winnerDisplay) {
				setWinnerDisplay(trickWinner.name)

				gameRef
					.update({
						currentPlayer: trickWinner.key,
						currentRound: {
							...game.currentRound,
							tricks: [...(game.currentRound.tricks || []), trickWinner.key],
						},
					})
					.then(() => {
						setTimeout(() => {
							Promise.all(
								sortedPlayers.map(({ key }) => {
									return playersRef.child(key).update({ playedCard: null })
								})
							).then(() => {
								setWinnerDisplay(null)
							})
						}, SHOW_WINNER_TIMEOUT)
					})
			}
		}
	}, [sortedPlayers, cardsPlayed, game, deck, winnerDisplay, setWinnerDisplay])

	// Game
	useEffect(() => {
		gameRef.on('value', (snapshot) => {
			if (!snapshot.val()) {
				startNewGame()
				return
			}
			setGame(snapshot.val())
		})

		deckRef.once('value').then((snapshot) => {
			setDeck(snapshot.val())
		})

		window.addEventListener('unload', () => {
			console.log('UNLOAD')
			//   playersRef.remove()
		})
	}, [])

	const startNewGame = async () => {
		const newDeck = shuffle(generateNewDeck())
		setDeck(newDeck)
		await deckRef.set(newDeck)

		if (game) {
			await gameRef.remove(game.id)
		}

		const newGame = {
			name: 'Game',
			currentRound: { number: 1, bids: [], tricks: [] },
			rounds: [],
		}
		setGame(newGame)
		await gameRef.set(newGame)

		Promise.all(
			Object.entries(players).map(([id, player]) => {
				return playersRef.child(id).update({ cards: [], playedCard: null })
			})
		).then(() => {
			dealCards(newDeck, newGame)
		})
	}

	const dealCards = (deck, game) => {
		const newDeck = [...deck]
		Object.entries(players).forEach(([id, player]) => {
			const cardsForPlayer = []
			const cardsToDeal = game.rounds ? game.rounds.length + 1 : 1

			for (let i = 0; i < cardsToDeal; i++) {
				cardsForPlayer.push(newDeck.pop())
			}
			playersRef.child(id).update({ cards: cardsForPlayer })
		})

		setDeck(newDeck)
		deckRef.set(newDeck)
	}

	const finishRound = useCallback(
		async (winner) => {
			const nextGame = {
				currentPlayer: sortedPlayers[0].key,
				currentRound: {
					bids: [],
					number: game.rounds ? game.rounds.length + 1 : 1,
					tricks: [],
				},
				rounds: [...(game.rounds || []), game.currentRound],
			}
			setGame(nextGame)
			await gameRef.update(nextGame)
			Object.entries(players).forEach(([id, player]) => {
				playersRef.child(id).update({ cards: [], playedCard: null })
			})
			const newDeck = shuffle(generateNewDeck())
			setDeck(newDeck)
			deckRef.set(newDeck).then(() => {
				dealCards(newDeck, nextGame)
			})
		},
		[sortedPlayers]
	)

	const totals = useMemo(() => {
		if (scoreBoard) {
			const t = {}
			for (let i = 0; i < scoreBoard.length; i++) {
				const round = scoreBoard[i]
				round.forEach((score) => {
					if (!t[score.player]) {
						t[score.player] = 0
					}
					t[score.player] += score.tricks
				})
			}
			return t
		}
	}, [scoreBoard])

	if (!players) {
		return (
			<div
				style={{
					backgroundColor: '#393433',
					height: '100vh',
					display: 'flex',
					height: '100vh',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<h1 style={{ marginTop: 0, textAlign: 'center', color: '#E55934' }}>Go to hell!</h1>
				<h2>waiting for players to join...</h2>
			</div>
		)
	}

	const showBids =
		game &&
		game.currentRound &&
		game.currentRound.bids &&
		Object.keys(game.currentRound.bids).length === Object.keys(players).length

	return (
		<div style={{ backgroundColor: '#2F4F2F', height: '100vh' }}>
			{game && (
				<h3 style={{ margin: 0, padding: 12 }}>Round {game.rounds ? game.rounds.length + 1 : 1}</h3>
			)}
			{/* {scoreBoard && <ScoreBoard scores={scoreBoard} totals={totals} />} */}
			{game && game.rounds && game.rounds.map((round, i) => <div key={i}>{round.winner}</div>)}
			<div>
				{game &&
					game.currentRound &&
					Object.entries(players).map(([key, player], index) => (
						<PlayerContainer
							key={key}
							index={index}
							length={Object.entries(players).length}
							player={player}
							bid={game.currentRound.bids && game.currentRound.bids[key]}
							showBid={showBids}
							isCurrent={game.currentPlayer === key && player.cards && player.cards.length}
							tricks={
								game.currentRound.tricks &&
								game.currentRound.tricks.filter((trickOwner) => trickOwner === key).length
							}
							totalScore={totals && totals[key]}
						/>
					))}
			</div>
			{winnerDisplay && (
				<div className="winner-display">
					<img src={`https://api.adorable.io/avatars/60/${winnerDisplay}@adorable.io.png`} />
					<h2>
						Winner: <b>{winnerDisplay}</b>
					</h2>
				</div>
			)}

			<div>
				<button className="btn" onClick={() => startNewGame()}>
					New game
				</button>
				<button className="btn" onClick={() => dealCards(deck, game)}>
					Deal
				</button>
				<button className="btn" onClick={() => finishRound()}>
					Finish round
				</button>
				<button
					className="btn"
					onClick={() => {
						playersRef.remove()
					}}
				>
					Kick all
				</button>
			</div>
			{deck && deck.length && (
				<div style={{ width: 100, padding: 12 }}>
					<h3 style={{ margin: 0, marginBottom: 8 }}>Power suit:</h3> <Trump card={deck[0]} />
				</div>
			)}
		</div>
	)
}
