import React from 'react'
import Card from './Card'

export const PlayerContainer = ({
	player,
	index,
	length,
	bid,
	tricks,
	isCurrent,
	totalScore,
	showBid,
}) => {
	const getStyle = () => {
		const styles = {
			position: 'absolute',
		}

		if (index === 0) {
			styles.flexDirection = 'column'
			styles.transform = 'translateX(-50%)'
			if (length <= 4) {
				styles.top = 0
				styles.left = '50%'
			} else {
				styles.top = 0
				styles.left = '40%'
			}
		}

		if (index === 1) {
			styles.flexDirection = 'row-reverse'
			styles.transform = 'translateY(-50%)'
			if (length <= 5) {
				styles.top = '50%'
				styles.right = 0
			} else {
				styles.top = '33%'
				styles.right = 0
			}
		}

		if (index === 2) {
			styles.flexDirection = 'column-reverse'
			styles.transform = 'translateX(50%)'
			if (length <= 6) {
				styles.bottom = 0
				styles.right = '50%'
			} else {
				styles.bottom = 0
				styles.right = '33%'
			}
		}

		if (index === 3) {
			styles.flexDirection = 'row'
			styles.transform = 'translateY(50%)'
			if (length <= 7) {
				styles.bottom = '50%'
				styles.left = 0
			} else {
				styles.bottom = '33%'
				styles.left = 0
			}
		}

		if (index === 4) {
			styles.transform = 'translateX(-50%)'
			styles.flexDirection = 'column'
			styles.top = 0
			styles.left = '60%'
		}

		if (index === 5) {
			styles.transform = 'translateY(-50%)'
			styles.flexDirection = 'row-reverse'
			styles.top = '66%'
			styles.right = 0
		}

		if (index === 6) {
			styles.transform = 'translateX(50%)'
			styles.flexDirection = 'column-reverse'
			styles.bottom = 0
			styles.right = '66%'
		}

		if (index === 7) {
			styles.transform = 'translateY(50%)'
			styles.flexDirection = 'row'
			styles.bottom = '66%'
			styles.left = 0
		}

		return styles
	}

	const getStarStyles = () => {
		const styles = {}

		if (index % 2 !== 0) {
			styles.flexDirection = 'column'
		} else {
			styles.flexDirection = 'row'
		}

		if (index % 5 === 0 || index === 1) {
			styles.marginLeft = 20
		}

		return styles
	}

	return (
		<div style={getStyle()} className={`player-container ${isCurrent && 'is-current'}`}>
			<div className="player-info">
				<div>
					<div className="avatar">
						<img src={`https://api.adorable.io/avatars/60/${player.name}@adorable.io.png`} />
					</div>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						{player.cards &&
							player.cards.map((card, i) => <div key={i} className="player-cards"></div>)}
					</div>
				</div>
				<h2 className="player-name">{player.name}</h2>
				<h2 className="player-score">{totalScore}</h2>
			</div>
			{showBid && (
				<div className="bid-stars" style={getStarStyles()}>
					{bid > 0 &&
						Array(bid)
							.fill('★')
							.map((star, i) => (
								<span className={`bid-star ${i < tricks && 'trick'}`} key={i}>
									{star}
								</span>
							))}
					{tricks > bid &&
						Array(tricks - bid)
							.fill('★')
							.map((star, i) => (
								<span className={`bid-star trick-bad`} key={i}>
									{star}
								</span>
							))}
				</div>
			)}
			<div className="player-card">
				{player.playedCard && (
					<div className="card">
						<Card card={player.playedCard} />
					</div>
				)}
			</div>
		</div>
	)
}
