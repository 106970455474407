import * as firebase from 'firebase'

console.log('INIT APP')

const app = firebase.initializeApp({
  apiKey: 'AIzaSyAXFjp0Lygpl42UE_JsqFCzZR6TdAZ5R3Q',
  authDomain: 'gotoh-8bd8e.firebaseapp.com',
  databaseURL: 'https://gotoh-8bd8e.firebaseio.com',
  projectId: 'gotoh-8bd8e',
  storageBucket: 'gotoh-8bd8e.appspot.com',
  messagingSenderId: '806462441768',
  appId: '1:806462441768:web:bb12b053f54232edbc281d',
})

export default app
