import club_1 from 'svg-cards/png/2x/club_1.png'
import club_2 from 'svg-cards/png/2x/club_2.png'
import club_3 from 'svg-cards/png/2x/club_3.png'
import club_4 from 'svg-cards/png/2x/club_4.png'
import club_5 from 'svg-cards/png/2x/club_5.png'
import club_6 from 'svg-cards/png/2x/club_6.png'
import club_7 from 'svg-cards/png/2x/club_7.png'
import club_8 from 'svg-cards/png/2x/club_8.png'
import club_9 from 'svg-cards/png/2x/club_9.png'
import club_10 from 'svg-cards/png/2x/club_10.png'
import club_jack from 'svg-cards/png/2x/club_jack.png'
import club_queen from 'svg-cards/png/2x/club_queen.png'
import club_king from 'svg-cards/png/2x/club_king.png'
import diamond_1 from 'svg-cards/png/2x/diamond_1.png'
import diamond_2 from 'svg-cards/png/2x/diamond_2.png'
import diamond_3 from 'svg-cards/png/2x/diamond_3.png'
import diamond_4 from 'svg-cards/png/2x/diamond_4.png'
import diamond_5 from 'svg-cards/png/2x/diamond_5.png'
import diamond_6 from 'svg-cards/png/2x/diamond_6.png'
import diamond_7 from 'svg-cards/png/2x/diamond_7.png'
import diamond_8 from 'svg-cards/png/2x/diamond_8.png'
import diamond_9 from 'svg-cards/png/2x/diamond_9.png'
import diamond_10 from 'svg-cards/png/2x/diamond_10.png'
import diamond_jack from 'svg-cards/png/2x/diamond_jack.png'
import diamond_queen from 'svg-cards/png/2x/diamond_queen.png'
import diamond_king from 'svg-cards/png/2x/diamond_king.png'
import heart_1 from 'svg-cards/png/2x/heart_1.png'
import heart_2 from 'svg-cards/png/2x/heart_2.png'
import heart_3 from 'svg-cards/png/2x/heart_3.png'
import heart_4 from 'svg-cards/png/2x/heart_4.png'
import heart_5 from 'svg-cards/png/2x/heart_5.png'
import heart_6 from 'svg-cards/png/2x/heart_6.png'
import heart_7 from 'svg-cards/png/2x/heart_7.png'
import heart_8 from 'svg-cards/png/2x/heart_8.png'
import heart_9 from 'svg-cards/png/2x/heart_9.png'
import heart_10 from 'svg-cards/png/2x/heart_10.png'
import heart_jack from 'svg-cards/png/2x/heart_jack.png'
import heart_queen from 'svg-cards/png/2x/heart_queen.png'
import heart_king from 'svg-cards/png/2x/heart_king.png'
import spade_1 from 'svg-cards/png/2x/spade_1.png'
import spade_2 from 'svg-cards/png/2x/spade_2.png'
import spade_3 from 'svg-cards/png/2x/spade_3.png'
import spade_4 from 'svg-cards/png/2x/spade_4.png'
import spade_5 from 'svg-cards/png/2x/spade_5.png'
import spade_6 from 'svg-cards/png/2x/spade_6.png'
import spade_7 from 'svg-cards/png/2x/spade_7.png'
import spade_8 from 'svg-cards/png/2x/spade_8.png'
import spade_9 from 'svg-cards/png/2x/spade_9.png'
import spade_10 from 'svg-cards/png/2x/spade_10.png'
import spade_jack from 'svg-cards/png/2x/spade_jack.png'
import spade_queen from 'svg-cards/png/2x/spade_queen.png'
import spade_king from 'svg-cards/png/2x/spade_king.png'

export const cardImages = {
  club_1,
  club_2,
  club_3,
  club_4,
  club_5,
  club_6,
  club_7,
  club_8,
  club_9,
  club_10,
  club_jack,
  club_queen,
  club_king,
  diamond_1,
  diamond_2,
  diamond_3,
  diamond_4,
  diamond_5,
  diamond_6,
  diamond_7,
  diamond_8,
  diamond_9,
  diamond_10,
  diamond_jack,
  diamond_queen,
  diamond_king,
  heart_1,
  heart_2,
  heart_3,
  heart_4,
  heart_5,
  heart_6,
  heart_7,
  heart_8,
  heart_9,
  heart_10,
  heart_jack,
  heart_queen,
  heart_king,
  spade_1,
  spade_2,
  spade_3,
  spade_4,
  spade_5,
  spade_6,
  spade_7,
  spade_8,
  spade_9,
  spade_10,
  spade_jack,
  spade_queen,
  spade_king,
}
