export const determineWinner = (players, trump) => {
  let winner
  const firstCard = players[0].playedCard

  for (let i = 0; i < players.length; i++) {
    const curr = players[i]
    if (i === 0) {
      winner = curr
    } else {
      if (curr.playedCard.suit !== trump.suit) {
        if (winner.playedCard.suit !== trump.suit) {
          if (
            curr.playedCard.suit === firstCard.suit &&
            curr.playedCard.face > winner.playedCard.face
          ) {
            winner = curr
          }
        }
      }

      if (curr.playedCard.suit === trump.suit) {
        if (winner.playedCard.suit === trump.suit) {
          if (curr.playedCard.face > winner.playedCard.face) {
            winner = curr
          }
        } else {
          winner = curr
        }
      }
    }
  }
  return winner
}
