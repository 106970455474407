import React from 'react'

import './App.css'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { PlayerView } from './PlayerView'
import { BoardView } from './BoardView'
import { ReactComponent as SvgCard } from 'svg-cards/svg-cards.svg'
function App() {
  return (
    <Router>
      <div style={{ height: 0 }}>
        <SvgCard />
      </div>

      <div className="App">
        <Switch>
          <Route exact path="/">
            <PlayerView />
          </Route>
          <Route path="/board">
            <BoardView />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
