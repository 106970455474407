import React from 'react'
import { cardImages } from './cardImages'

// 0.69

const getSuitIcon = (suit) => {
	switch (suit) {
		case 'S':
			return '♠'
		case 'C':
			return '♣'
		case 'H':
			return '♥'
		case 'D':
			return '♦	'
		default:
			return 'err'
	}
}

const Card = ({ card, onClick }) => {
	let color = 'red'
	const { suit, face } = card

	let namedSuit = suit
	let namedFace = face

	switch (suit) {
		case 'C':
			color = 'black'
			namedSuit = 'club'
			break
		case 'H':
			color = 'red'
			namedSuit = 'heart'
			break
		case 'D':
			color = 'red'
			namedSuit = 'diamond'
			break
		case 'S':
			color = 'black'
			namedSuit = 'spade'
			break
		default:
	}

	if (face === 12) {
		namedFace = 'queen'
	}

	if (face === 13) {
		namedFace = 'king'
	}

	if (face === 11) {
		namedFace = 'jack'
	}

	if (face === 14) {
		namedFace = 1
	}

	return <img style={{ width: '100%' }} src={cardImages[`${namedSuit}_${namedFace}`]} />
}

const trumpStyle = {
	backgroundColor: 'white',
	borderRadius: 10,
	width: 60,
	marginLeft: 12,
}

export const Trump = ({ card }) => {
	let color = 'red'
	const { suit } = card

	if (suit === 'C' || suit === 'S') {
		color = 'black'
	}

	return (
		<div style={trumpStyle}>
			<h1 style={{ color, fontSize: 64, margin: 0 }}>{getSuitIcon(suit)}</h1>
		</div>
	)
}

export default Card
